import React, { Component } from 'react'
import { Col, Container } from 'reactstrap'

class Benefit extends Component {
    constructor(props) {
        super(props)
        this.benefit = props.benefit
    }

    render() {
        return (
            <Col md="6" xs="12">
                <h3>{this.benefit.benefit_title}</h3>
                <img src={this.benefit.benefit_image} />
                <p>{this.benefit.benefit_description}</p>
            </Col>
        )
    }
}

export default Benefit