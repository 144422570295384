import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout'
import Benefit from '../components/benefit';
export const ClubHousePageTemplate = ({
  title,
  benefits,
}) => (
  <section id="clubHouse">
    <Row>
      <Col md={2} xs={12} className="sideBar" >
        <a href='/'><img src="/img/CDV.png" /></a>
      </Col>
      <Col className="title"><h1>{title}</h1></Col>
    </Row>
    <Container>
      <Row className="benefits">
        {benefits.map(benefit => {
          return ( 
              <Benefit benefit={benefit} />
          )
        })}
      </Row>
    </Container>
  </section>
)

ClubHousePageTemplate.propTypes = {

  title: PropTypes.string,
  benefits: PropTypes.array,
}

const ClubHousePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout currentSlug={frontmatter.title} title={frontmatter.title} description={frontmatter.description}>
      <ClubHousePageTemplate
        title={frontmatter.title}
        benefits={frontmatter.benefits}
      />
    </Layout>
  )
}

ClubHousePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ClubHousePage

export const pageQuery = graphql`
  query ClubHousePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "club-house-page" } }) {
      frontmatter {
        title
        description
        benefits {
          benefit_title
          benefit_image
          benefit_description
        }
      }
    }
  }
`